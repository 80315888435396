import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Tag } from '../../atoms/Tag';
import { theme } from '../../../../domain/theme';
import { breakpointService } from '../../../../domain/services/breakpointService';
import { sortAlphabetically } from '../../../../domain/utils/arrayUtils';
import { t } from '../../../../domain/services/configService';

export const TagList = ({ tags }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleMore = () => {
    setIsOpen(true);
  };
  let hubTags = tags.filter(tag => tag.url);
  sortAlphabetically(hubTags, 'name');
  // Move parent tags to the end of the array.
  const parentHubTags = [];
  const childrenHubTags = [];
  hubTags.forEach(hubTag => {
    if (hubTag.isParent) {
      parentHubTags.push(hubTag);
    } else {
      childrenHubTags.push(hubTag);
    }
  });
  hubTags = childrenHubTags.concat(parentHubTags);

  const maxTags =
    breakpointService.getCurrentBreakpoint().name === 'mobile' ? 8 : 10;
  const styles = {
    ul: css`
      display: flex;
      flex-wrap: wrap;
      ${!isOpen &&
      `li:nth-of-type(n + ${maxTags + 1}) {
        display: none;
      }
      li:last-child {
        display: block;
      }`}
    `,
    li: css`
      list-style: none;
      margin-right: 5px;
      margin-bottom: 5px;
      ${theme.mq.tablet} {
        margin-right: 15px;
        margin-bottom: 20px;
      }
    `,
    more: css`
      border: none;
    `
  };
  return hubTags.length > 0 ? (
    <ul css={styles.ul}>
      {hubTags.map(tag => (
        <li css={styles.li} key={tag.id}>
          <Tag url={tag.url} name={tag.name} />
        </li>
      ))}
      {hubTags.length > maxTags && !isOpen && (
        <li css={styles.li}>
          <button
            css={styles.more}
            type="button"
            onClick={handleMore}
            label={t('More')}
          >
            <Tag name={t('__MORE__')} />
          </button>
        </li>
      )}
    </ul>
  ) : (
    <div />
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  )
};

TagList.defaultProps = {
  tags: []
};
