import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { SmartLink } from '../../SmartLink';
import { theme } from '../../../domain/theme';

const styles = {
  tagContainer: css`
    border-radius: 2px;
    background-color: #f4f4f4;
    ${theme.font.family('boldWeb')};
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 3px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    &:hover {
      background: #000000;
      color: #fff;
      a,
      span {
        color: #fff;
      }
    }
    a,
    span {
      display: inline-block;
      color: #000000;
      padding: 3px 10px;
      ${theme.mq.tablet} {
        padding: 6px 28px;
      }
    }
  `
};
export const Tag = ({ url, name }) => {
  return (
    <div css={styles.tagContainer}>
      {url ? <SmartLink to={url}>{name}</SmartLink> : <span>{name}</span>}
    </div>
  );
};

Tag.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string.isRequired
};

Tag.defaultProps = {
  url: undefined
};
